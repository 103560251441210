$(document).on('turbolinks:load', function() {

  if ($('.pagination').length) {
    $(window).scroll(function() {
      var url;
      url = $('.pagination .next a').attr('href');
      if (url && $(window).scrollTop() > $(document).height() - $(window).height() - 50) {
        $('.pagination').text("Loading...");
        return $.getScript(url);
      }
    });
    $(window).scroll();
  }

  // Search by date on the entire Camp Roster Report
  $("#team_roster_date").on('change', function() {
    if (!isValidDate($(this).val() ) ) {
      return false;
    }

    // when is valid date
    $('#roster-count-submit-btn').show();
  });

  // Search by date on the entire Camp Roster Report
  $("#camper_log_book_date, #bus_roster_date, .announcement-date").on('change', function() {
    if (!isValidDate($(this).val() ) ) {
      return false;
    }

    // when is valid date
    $(this).parents('form').submit();
  });

  // Search by last name on the entire Camp Roster Report
  // $("#camper_log_book_camper_last_name").keyup(function() {
  //   if ($(this).val().length > 2 || $(this).val().length == 0) {
  //     $(this).parents('form').submit();
  //   }
  // });

  // https://stackoverflow.com/questions/6177975/how-to-validate-date-with-format-mm-dd-yyyy-in-javascript
  function isValidDate(dateString)
{
    // First check for the pattern
    var regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

    if(!regex_date.test(dateString))
    {
        return false;
    }

    // Parse the date parts to integers
    var parts   = dateString.split("-");
    var day     = parseInt(parts[2], 10);
    var month   = parseInt(parts[1], 10);
    var year    = parseInt(parts[0], 10);

    // Check the ranges of month and year
    if(year < 1000 || year > 3000 || month == 0 || month > 12)
    {
        return false;
    }

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    {
        monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  //Invoke print command if print report is loaded
  if ($("#print-report").length) {
    window.print();
  }


  // Compute gran totals on Team Roster Report and set values to header
  if ($(".total-signed-in-today").length && $(".total-signed-in-now")) {
    let total_signed_in_today = 0;
    $(".total-signed-in-today").map(function(idx, elem) {
      total_signed_in_today += $(elem).text() * 1; } );
    $("#total-signed-in-today").text(total_signed_in_today);

    let total_signed_in_now = 0;
    $(".total-signed-in-now").map(function(idx, elem) {
      total_signed_in_now += $(elem).text() * 1; } );
    $("#total-signed-in-now").text(total_signed_in_now);
  }


  // Team Roster, Days Registered vs Attended report
  $(".counselor-location, .days-report-days-registered").on('change', function() {
    $.rails.fire($(this).parents('form')[0], 'submit');
  });

  // Days Attended vs Days Registered Report, Camper Attendance Report
  $("#date_year, #report_camper_id").on('change', function() {
    $(this).parents('form').submit();
  });

  // Detailed Team Roster report
  $('table.detailed-team-roster').DataTable(
    {
      'paging': false,
      'info': false,
      'searching': false,
      'columnDefs': [
        {
          "targets": [7, 8],
          "sortable": false
        }
      ]
    });

  $('table.detailed-team-roster .counselor-select').on('change', function() {
    // Set counselor name to hidden field so dataTables can sort
    $(this).siblings('.counselor').text($(this).find(':selected').text());
    $(this).parent('form').find('input[type="submit"]').click();
  });


  // Bus Roster
  $('.add-camper-to-bus-roster, .camper-attendance-report').select2();


  // Bulk Sign out
  $('#sign-out-all').on('change', function() {
    if ($(this).is(':checked')) {
      $('table#bulk-sign-out .checkbox').prop('checked', true);
    } else {
      $('table#bulk-sign-out .checkbox').prop('checked', false);
    }
  });

});
