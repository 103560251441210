import Rails from "@rails/ujs"

$(document).on('turbolinks:load', function() {

  // Wihtout more coding, the html select input is faster than select2. Disabling.
  //$('.planned-activity-nice-select').select2();

  $(".planned-activity").on('change', function() {
    $.rails.fire($(this).parents('tr').find('form')[0], 'submit');
  });


  $(".scheduled-activity").on('mousedown', function() {
    let activity = $(this).html();

    $(`textarea`).removeClass('border-primary');
    if (activity != '') {
      $(`textarea:contains(${activity})`).addClass('border-primary');
    }
  });

  $(".scheduled-activity").on('change', function() {
    $.rails.fire($(this).parent()[0], 'submit');

    let val = $(this).val();
    $(this).html(val)

    if ($(this).html() != '') {
      $(this).removeClass('border-danger')
    }
    $(`textarea`).removeClass('border-primary');
  });

  $(".period-time-range").on('change', function() {
    $.rails.fire($(this).parent()[0], 'submit');
  });

  $(".am-location, .pm-location").on('change', function() {
    $.rails.fire($(this).parent()[0], 'submit');
  });

});
