import consumer from "./consumer"

$(document).on('turbolinks:load', function() {
  if ($('body').attr('data-controller') === 'going_home_features') {
    const soundEffect = new Audio();
    soundEffect.src = 'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
    soundEffect.autoplay = true;
    $(document).on('click touchend', function() {
      soundEffect.play()
      // lets show a sound icon instead of showing the audio player
      // $('.my_audio').show();
      $('#sound-enabled').show();
      $('#sound-disabled').hide();
    });
    consumer.subscriptions.create("GoingHomeChannel", {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to who's going home channel!")
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        var teamFilterActive = Boolean(false);
        var teamFilterMatch = Boolean(false);
        var locationFilterActive = Boolean(false);
        var locationFilterMatch = Boolean(false);
        console.log(data)
        if ($('#team-filter-active').length && data.team && $('#team-filter-active').attr('data-team') === data.team) {
          console.log("Team filter active and camper matches the team")
          teamFilterActive = Boolean(true);
          teamFilterMatch = Boolean(true);
        } else if ($('#team-filter-active').length && data.team && $('#team-filter-active').attr('data-team') !== data.team) {
          console.log("Team filter active but different team than current filter")
          teamFilterActive = Boolean(true);
        }

        if ($('#location-filter-active').length && data.location && $('#location-filter-active').attr('data-location') === data.location) {
          console.log("Location filter active and camper matches the location")
          locationFilterActive = Boolean(true);
          locationFilterMatch = Boolean(true);
        } else if ($('#location-filter-active').length && data.location && $('#location-filter-active').attr('data-location') !== data.location) {
          console.log("Location filter active but different location than current filter")
          locationFilterMatch = Boolean(true);
        }

        if ((teamFilterActive === true && teamFilterMatch === true) || (locationFilterActive === true && locationFilterMatch === true)) {
          soundEffect.src = 'Fun-Dial.mp3';
          soundEffect.play();
          setTimeout(function(){
              soundEffect.src = '';
          }, 5500);
          $.ajax({url: '/going_home_features/insert_camper?clb='+data.camper_log_book_id, type: 'get', dataType: 'script'});
        }

        if (teamFilterActive === false && locationFilterMatch === false) {
          console.log("No filter")
          $.ajax({url: '/going_home_features/insert_camper?clb='+data.camper_log_book_id, type: 'get', dataType: 'script'});
        }

      }
    });

  } else {
    consumer.subscriptions.remove("GoingHomeChannel")
    console.log("Disconnected")
  }
});
