// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


var jQuery = require('jquery');
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'bootstrap';
import "@fortawesome/fontawesome-free/js/all";
import './sign_in_wizard';
import './john_hancock_signatures';
import './reports';
import './pool_trackings';
import './daily_schedule';
import './jquery.easy-autocomplete';
import './sign_in_out';
import './timeclocks';

require("@nathanvda/cocoon");

require("trix")
require("@rails/actiontext")
