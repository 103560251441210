$(document).on('turbolinks:load', function() {

  // For new records disable all bus home checkboxes
  // For existing records don't disable matching bus home checkbox if camper checkbox is checked
  $.each($("#select-camper .custom-checkbox"), function(index, checkbox_wrapper) {
    var checkbox = $(checkbox_wrapper).find('input[type="checkbox"]');
    if (!$(checkbox).is(':checked') ) {
      var matching_row = $("#select-bus-home").find(`[data-row-id=${$(this).data('row-id')}]`);
      var matching_checkbox = matching_row.find('input[type="checkbox"]');
      $(matching_checkbox).attr('disabled', true);
    }
  });

  // When selecting camper enable matching bus home checkbox, and disable it if unchecked
  $("#select-camper .custom-checkbox").on('click', function() {
    var matching_row = $("#select-bus-home").find(`[data-row-id=${$(this).data('row-id')}]`);
    var matching_checkbox = matching_row.find('input[type="checkbox"]');
    if ($(this).find('input[type="checkbox"]').is(':checked')) {
     matching_checkbox.attr('disabled', false);
    } else {
      matching_checkbox.attr('disabled', true);
      matching_checkbox.prop("checked", false);
    }
  });

  // Prevent camper sign in/out if signature is blank
  // Once added enable submit button
  $("#JohnHancock-canvas").on('click touchstart', function() {
    $('input.add-signature').attr('disabled', false);
  });

  // Add selected class if location radio is clicked
  $(".location-radio input[type='radio']").on('click', function() {
    $(".location-radio").removeClass('selected');
    $(this).parent().addClass('selected');
  });

  // Sign in Authorized Pick Up and Signature page
  // When selecting other, enable input field and set value to checkbox
  $("input[type='radio']").on('change', function() {
    let radio_with_input_text = $("input[type='radio'].authorized-other");
    if (radio_with_input_text.length == 0) {
      return;
    }
    if (radio_with_input_text.is(':checked') ) {
      $(`input[type='text'][data-row-id=${radio_with_input_text.data('row-id')}]`).attr('disabled', false);
      $(`input[type='text'][data-row-id=${radio_with_input_text.data('row-id')}]`).attr('required', 'required');
    } else {
      $(`input[type='text'][data-row-id=${radio_with_input_text.data('row-id')}]`).attr('disabled', true);
      $(`input[type='text'][data-row-id=${radio_with_input_text.data('row-id')}]`).removeAttr('required');
    }
  });


  $("input[type='text'].other-pickup").on('change', function()  {
    $(`input[type='radio'][data-row-id=${$(this).data('row-id')}]`).val($(this).val());
  });

});
