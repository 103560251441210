$(document).on('turbolinks:load', function() {
  $input = $(".autocomplete-search-employees");
  var options = {
    theme: "blue",
    getValue: "detailed_name",
    url: function(phrase) {
      return "/employees/search.json?name=" + phrase;
    },
    categories: [
      {
        listLocation: "employees"
      }
    ],
    list: {
      onChooseEvent: function(){
        $input.getSelectedItemData().url
        console.log(url)
        var value = $input.getSelectedItemData().detailed_name;
			  $input.val(value).trigger("change");
      }
    }
  }
  $input.easyAutocomplete(options);
});